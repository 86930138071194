export const getNumeric = (s) => {
  return s.replace(/\D/g, '')
}

export const numberWithCommas = (x) => {
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatNumber = (x) => {
  return numberWithCommas(Number(x).toFixed(2))
}

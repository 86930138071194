<template>
  <input
    :id="id"
    ref="inpt"
    type="tel"
    class="form-control"
    :class="inputClass"
    :name="name"
    :placeholder="$t('register.phone')"
    :required="required"
    :readonly="readonly"
    autocomplete="tel"
    @input="inputChanged"
  >
</template>

<script>
import IntlTelInput from 'intl-tel-input'

export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: String,
      default: null
    },
    country: {
      type: String,
      default: 'DE'
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: [String, Object],
      default: () => null
    },
    customContainerClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      el: null,
      countryCode: null,
      timer: null,
      input: null
    }
  },
  watch: {
    value (newValue) {
      this.input.setNumber(newValue);
    },
    country (newValue) {
      this.input.setCountry(newValue)
    }
  },
  mounted () {
    window.intlTelInput = IntlTelInput
    let telInputOptions = {}
    if (this.country) {
      telInputOptions.initialCountry = this.country
    }

    this.el = this.$refs.inpt

    this.input = window.intlTelInput(this.el, {
      // utilsScript: '/js/vendor/utils.js',
      nationalMode: false,
      autoPlaceholder: true,
      separateDialCode: true,
      // excludeCountries: window.AppConfig.blockedCountries,
      initialCountry: this.country ? this.country : 'auto',
      preferredCountries: ['DE', 'FR', 'IE', 'ES', 'GB', 'US', 'CA'],
      customContainer: this.customContainerClass + ' w-100',
      geoIpLookup: (success, failure) => {
        axios.get(this.$api.countries + '/geo').then(response => {
          let countryCode = null
          if (response.data.data.iso) {
            countryCode = response.data.data.iso
          }
          success(countryCode)
        }).catch(error => {
          success('')
        })
      }
    })
    if (this.value) {
      this.input.setNumber(this.value)
    }

    this.el.addEventListener('countrychange', () => {
      this.countryCode = this.input.getSelectedCountryData().iso2 // countryData.iso2;
      this.inputChanged()
    })
    this.timer = setTimeout(() => {
      this.getCountryData()
    }, 500)
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.input.destroy()
  },
  methods: {
    inputChanged () {
      this.getCountryData()
      this.$emit('input', this.input.getNumber())
    },
    getCountryData () {
      const countryData = this.input.getSelectedCountryData()
      if (countryData) {
        this.countryCode = countryData.iso2
      }
    },
    isValid () {
      return this.input.isValidNumber()
    }
  }
}
</script>

<style>
  .intl-tel-input {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
  }
</style>

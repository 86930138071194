import { isEmpty } from '../helpers/objects'

export default {
  data () {
    return {
      errors: {}
    }
  },
  created () {
    this.clearErrors()
  },
  computed: {
    hasErrors () {
      return !isEmpty(this.errors)
    }
  },
  methods: {
    clearErrors () {
      let formField = 'values'
      if (this.hasOwnProperty('formField')) {
        formField = this.formField
      }
      if (this.hasOwnProperty(formField)) {
        let valueKeys = Object.keys(this[formField])
        let errorObject = {}
        valueKeys.forEach((value) => {
          errorObject[value] = []
        })
        this.errors = Object.assign({}, errorObject)
      }
    },
    addError (field, text) {
      if (this.errors.hasOwnProperty(field)) {
        this.errors[field].push(text)
      }
    },
    setErrors (errors) {
      this.errors = Object.assign({}, errors)
    }
  }
}

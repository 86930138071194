<template>
  <div
    class="vld-parent"
    :style="loaderStyle"
  >
    <Loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="#d700a9"
      background-color="#e5e5f5"
      loader="dots"
      :width="64"
      :height="64"
    >
      <template
        v-if="title"
        slot="after"
      >
        <div class="loading-title">
          {{ title }}
        </div>
      </template>
      <div v-if="empty"></div>
    </Loading>
    <slot />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Loader',
  components: {
    Loading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fullPage: {
      type: Boolean,
      default: false
    },
    loaderStyle: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    empty: {
      type: Boolean,
      default: false
    }
  }
}
</script>
